import { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useApi, Service } from '../../config/useApi';
import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { getReleasePostfix, useScriptInject } from '../shared/helpers';

const StockLendingId = 'stock-lending';
let loaded = false;

const StockLending = () => {
  const { handleScriptInject } = useScriptInject();
  const { getApiUrl } = useApi();
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'corporate-actions-bundle',
  );

  setStartHandleTime();

  useEffect(() => {
    if (loaded && window.STOCK_LENDING_UI) {
      window.STOCK_LENDING_UI.render(document.getElementById(StockLendingId));
    }
    return () => {
      window.STOCK_LENDING_UI?.root?.unmount();
    };
  }, []);

  const handleScriptInjectCB = useCallback((newState: any, addedTags: any) => {
    handleScriptInject(addedTags, toLowerCase(MODULES.STOCK_LENDING), () => {
      logHandleTime();
      loaded = true;
      if (window.STOCK_LENDING_UI) {
        window.STOCK_LENDING_UI.render(document.getElementById(StockLendingId));
      }
    });
  }, []);

  const baseUrl = getApiUrl(Service.StockLending);
  const releasePostfix = useMemo(() => getReleasePostfix(), []);

  return (
    <>
      <Helmet
        script={
          loaded
            ? []
            : [
                {
                  type: 'module',
                  src: `${baseUrl}${releasePostfix}/static/js/main.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href={`${baseUrl}${releasePostfix}/static/css/main.css`}
        />
      </Helmet>
      <div id={StockLendingId} />
    </>
  );
};

export default StockLending;
