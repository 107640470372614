import React, { useEffect } from 'react';

import { appendIframe, getIframe } from './utils';

const CprmDjangoAdmin = () => {
  useEffect(() => {
    if (window.CPRM_UI?.api_urls.CPRM) {
      // TODO Move to branding
      const adminUrl = window.CPRM_UI.api_urls.CPRM.replace('backend-', '');
      const el = document.getElementsByClassName('CprmAdmin')[0];
      const iframe = getIframe(el, `${adminUrl}/django-admin/`);
      appendIframe(iframe, window.CPRM_UI.api_urls.CPRM);
    }
  }, [window.CPRM_UI]);

  return <div className="CprmAdmin" />;
};

export default CprmDjangoAdmin;
