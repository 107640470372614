import React, { createContext, useState, useMemo } from 'react';

import { MenuItemWithSearch } from '../menu/types';

interface GlobalSearchContextType {
  searchData: MenuItemWithSearch[];
  setSearchData: React.Dispatch<React.SetStateAction<MenuItemWithSearch[]>>;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

export const GlobalSearchContext = createContext<
  GlobalSearchContextType | undefined
>(undefined);

interface GlobalSearchProviderProps {
  children: React.ReactNode;
}

export const GlobalSearchProvider = ({
  children,
}: GlobalSearchProviderProps) => {
  const [searchData, setSearchData] = useState<MenuItemWithSearch[]>([]);
  const [inputValue, setInputValue] = useState('');

  const contextValue = useMemo(
    () => ({
      searchData,
      setSearchData,
      inputValue,
      setInputValue,
    }),
    [searchData, inputValue],
  );

  return (
    <GlobalSearchContext.Provider value={contextValue}>
      {children}
    </GlobalSearchContext.Provider>
  );
};
