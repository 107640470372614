import { RequestGlobalSearchResponse } from '../services/Types/nodeback.types';

import {
  parseCprmSearch,
  parseCrmSearch,
  parseNodeBackSearch,
  parseShaperSearch,
} from './helpers';
import { ParserFunction } from './types';

export const parsers: Record<
  keyof RequestGlobalSearchResponse,
  ParserFunction
> = {
  applications: parseCrmSearch,
  backoffice: parseNodeBackSearch,
  crm: parseCrmSearch,
  cprm: parseCprmSearch,
  notificationShaper: parseShaperSearch,
};
