import { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useApi, Service } from '../../config/useApi';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { getReleasePostfix, useScriptInject } from '../shared/helpers';

const BrandingId = 'branding-service-ui';
let loaded = false;

const Branding = () => {
  const { handleScriptInject } = useScriptInject();
  const { getApiUrl } = useApi();

  useEffect(() => {
    if (loaded && window.BRANDING_UI) {
      window.BRANDING_UI.render(document.getElementById(BrandingId));
    }
    return () => {
      window.BRANDING_UI?.root?.unmount();
    };
  }, []);

  const handleScriptInjectCB = useCallback((newState: any, addedTags: any) => {
    handleScriptInject(addedTags, toLowerCase(MODULES.BRANDING_UI), () => {
      loaded = true;
      if (window.BRANDING_UI) {
        window.BRANDING_UI.render(document.getElementById(BrandingId));
      }
    });
  }, []);

  const baseUrl = getApiUrl(Service.Branding);
  const releasePostfix = useMemo(() => getReleasePostfix(), []);

  return (
    <>
      <Helmet
        script={
          loaded
            ? []
            : [
                {
                  type: 'module',
                  src: `${baseUrl}${releasePostfix}/static/js/main.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href={`${baseUrl}${releasePostfix}/static/css/main.css`}
        />
      </Helmet>
      <div id={BrandingId} />
    </>
  );
};

export default Branding;
