import { Portal } from '@mui/material';
import { FC } from 'react';
import { styled } from 'react-ui-kit-exante';

import { MenuItem as MenuItemType } from '../../../config/types';
import { StyledProps } from '../../../shared/helpers/types';
import MenuStyles from '../Menu.module.css';
import { StyledMenuList } from '../Menu.styled';
import { MENU_WIDTH, SUBMENU_OFFSET } from '../const';

import { ISubmenuProps } from './types';

export const Root = styled('div')<StyledProps>(({ theme }) => ({
  backgroundColor: theme?.color.bg.basic,
  boxShadow: 'rgba(37, 44, 46, 0.1) 0px 4px 8px 0px',
  position: 'fixed',
  width: `${MENU_WIDTH}px`,
  transform: `translate(${MENU_WIDTH - SUBMENU_OFFSET}px) !important`,
  maxHeight: '100vh',
  height: '100vh',
  top: '0 !important' as '0',
  left: '16px',
  MozUserSelect: '-moz-none',
  WebkitUserSelect: 'none',
  OUserSelect: 'none',
  KhtmlUserSelect: 'none',
  userSelect: 'none',
  borderRadius: 'unset',
}));

export const Submenu: FC<React.PropsWithChildren<ISubmenuProps>> = ({
  item,
  renderMenuItem,
}) => {
  return (
    <Portal>
      <Root id="fade-menu">
        <div className={MenuStyles.OpenedSubmenuHeader}>{item.text}</div>
        <StyledMenuList>
          {item.children &&
            item.children.map((child: MenuItemType) => (
              <div key={child.text}>{renderMenuItem(child, item, true)}</div>
            ))}
        </StyledMenuList>
      </Root>
    </Portal>
  );
};
