import { styled } from 'react-ui-kit-exante';

export const StyledAuthLayout = styled('div')`
  position: relative;
  flex-basis: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 16px;

  min-height: calc(100vh - 16px * 2);

  background-color: ${({ theme }) => theme?.color.bg.primary};
`;

export const StyledAuthWrapper = styled('div')`
  padding: 24px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 40px 24px;

    width: 408px;
    margin: 0 auto;
  }
`;

export const StyledAuthContent = styled('div')`
  width: 100%;
`;

export const StyledAuthLogo = styled('div')`
  margin-bottom: 24px;
  min-height: 40px;

  text-align: center;

  .AuthLogoSvg {
    width: 144px;
    height: 32px;
  }
`;
