import { MenuItemWithSearch } from '../../menu/types';

/**
 * Filters nodes in a tree-like structure based on the presence of a `search` property or
 * if the node's `text` matches the `searchValue`.
 * Recursively processes the nodes and their children, retaining nodes that either have
 * a `search` property, match the `searchValue`, or have children that match the filter criteria.
 *
 * @param {MenuItemWithSearch[]} nodes - An array of nodes to be filtered.
 * @param {string} searchValue - A string used to match the `text` property of the nodes.
 *
 * @returns {MenuItemWithSearch[]} A new array containing only the nodes that have a `search` property,
 * match the `searchValue`, or have children that satisfy the filtering criteria.
 */
export function deleteNodesWithoutSearch(
  nodes: MenuItemWithSearch[],
  searchValue: string,
) {
  return nodes.reduce((acc, node) => {
    // Create a new node with updated children
    const newNode = { ...node };

    // Recursively filter child nodes
    if (newNode.children) {
      newNode.children = deleteNodesWithoutSearch(
        newNode.children,
        searchValue,
      );
    }

    // Check if the node itself or its children have a `search` property
    if (
      newNode.text.toLowerCase().includes(searchValue.toLowerCase()) ||
      newNode.search ||
      (newNode.children && newNode.children.length > 0)
    ) {
      acc.push(newNode);
    }

    return acc;
  }, [] as MenuItemWithSearch[]);
}
