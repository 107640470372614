import axios, { CancelTokenSource } from 'axios';
import { set } from 'lodash';
import {
  useCallback,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { Notification } from 'react-ui-kit-exante';

import { useMenuConfig } from '../../../config/useMenuConfig';
import { useLogHandleTime } from '../../../hooks/useLogHandleTime';
import { useAppDispatch } from '../../../store/hooks';
import { MenuItemWithSearch } from '../../menu/types';
import { useNodeBackService } from '../../services/NodeBack.service';
import { RequestGlobalSearchResponse } from '../../services/Types/nodeback.types';
import { parsers } from '../constants';
import {
  addContextSearch,
  deleteNodesWithoutSearch,
  generateLinkPathMap,
  renameDefaultLinkToLinkTo,
  reorderMenu,
  setCountForParentNodes,
} from '../helpers';
import { setCrmBadgesState } from '../reducer';

let cancelToken: CancelTokenSource;
// workaround to stop the previous code execution
let currentRequestId = 0;

/*
  We already have menuConfig; the search results are structurally similar to menuConfig in a different layout.
  We copy menuConfig variable and update it with the search results, then removing nodes that don't contain `search` results
 */
export function useOnSearch(
  setSearchData: Dispatch<SetStateAction<MenuItemWithSearch[]>>,
) {
  const dispatch = useAppDispatch();
  const { getMenuConfig } = useMenuConfig();
  const menuConfig = getMenuConfig();

  const [isLoading, setIsLoading] = useState(false);

  // search result is the menu in a different css
  let newSearchData: MenuItemWithSearch[] = structuredClone(menuConfig);
  newSearchData = reorderMenu(newSearchData);

  const linkPathMap = useMemo(
    () => generateLinkPathMap(newSearchData, ''),
    [menuConfig],
  );

  const { requestGlobalSearch } = useNodeBackService();

  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('run-global-search');

  const onSearch = useCallback(async (value: string) => {
    currentRequestId += 1;
    const requestId = currentRequestId;

    if (cancelToken) {
      cancelToken.cancel();
    }

    cancelToken = axios.CancelToken.source();

    const searchValue = value.trim();

    // We should display local search results before requesting server search https://jira.exante.eu/browse/ERU-1218
    setSearchData(deleteNodesWithoutSearch(newSearchData, searchValue));

    try {
      setIsLoading(true);

      setStartHandleTime();

      const response = await requestGlobalSearch(searchValue, cancelToken);

      if (requestId !== currentRequestId) {
        return;
      }

      logHandleTime();

      const dataForInsert: { path: string; text: string; link: string }[][] =
        [];

      let counts = {};

      // eslint-disable-next-line
      for (const serviceName in response.data) {
        const typedServiceName =
          serviceName as keyof RequestGlobalSearchResponse;

        if (!response.data[typedServiceName].data) {
          // eslint-disable-next-line no-continue
          continue;
        }

        const { searchResult, newCounts } = parsers[typedServiceName](
          response.data[typedServiceName],
          linkPathMap,
        );

        counts = {
          ...counts,
          ...newCounts,
        };

        dataForInsert.push(searchResult);
      }

      // transforms dataForInsert to array [{ path: { text, link } }]
      Object.entries(
        dataForInsert.flat().reduce((acc, { path, text, link }) => {
          if (!acc[path]) {
            acc[path] = [];
          }

          acc[path].push({ text, link });
          return acc;
        }, {} as Record<string, Array<{ link: string; text: string }>>),
      ).forEach(([path, rowsForInsert]) => {
        set(newSearchData, `${path}.search`, rowsForInsert);
      });

      Object.entries(counts).forEach(([url, count]) => {
        set(newSearchData, `${linkPathMap[url]}.count`, count);
      });

      newSearchData = deleteNodesWithoutSearch(newSearchData, searchValue);
      newSearchData = renameDefaultLinkToLinkTo(newSearchData);
      newSearchData = addContextSearch(newSearchData, searchValue);

      setCountForParentNodes(newSearchData);

      dispatch(setCrmBadgesState(counts));

      setSearchData(newSearchData);

      // this block is needed for filtering the All Applications and Clients tables
      if (window.CRM_UI?.search) {
        window.CRM_UI.search(value);
      }

      // reset for new search
      newSearchData = structuredClone(menuConfig);
      newSearchData = reorderMenu(newSearchData);

      setIsLoading(false);
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        Notification.error({
          title: error?.message,
        });
      }

      setIsLoading(false);
    }
  }, []);

  return { isLoading, onSearch, cancelToken };
}
