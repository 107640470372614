import { Collapse } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cn from 'classnames';
import { ReactNode, Ref, useMemo, forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { KitTheme } from 'react-ui-kit-exante';

import { ReactComponent as CollapseMainMenuIcon } from '../../assets/i/CollapseMainMenu.svg';
import { ReactComponent as CollapseSubmenuIcon } from '../../assets/i/CollapseSubmenu.svg';
import { ReactComponent as ExpandMainMenuIcon } from '../../assets/i/ExpandMainMenu.svg';
import { ReactComponent as ExpandSubmenuIcon } from '../../assets/i/ExpandSubmenu.svg';
import { getUsername } from '../services/Cookies.service';

import MenuStyles from './Menu.module.css';
import {
  SmallChip,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
} from './Menu.styled';
import { testLink, getHasActiveChildren } from './helpers';
import { MenuItemProps } from './types';

export const MenuItem = (props: MenuItemProps) => {
  const {
    text,
    itemChildren,
    children,
    open = false,
    linkTo,
    iconSrc,
    handleExpansion,
    isSubmenuItem,
    isSubmenuHeader,
    isMenuCollapsed,
    badgeCount = 0,
  } = props;
  const { hash = '', pathname = '' } = useLocation();

  const openedItemLink = pathname + hash;
  const isActiveLink = linkTo && testLink(openedItemLink, linkTo);
  const hasActiveChildren =
    itemChildren && getHasActiveChildren(openedItemLink, itemChildren);
  const isActive = isActiveLink || hasActiveChildren;

  const themeStyles: KitTheme = useTheme();
  const isMainMenuItem = !!iconSrc;

  const CustomLink = useMemo(
    () =>
      // eslint-disable-next-line react/display-name,react/no-unstable-nested-components
      forwardRef((linkProps, ref: Ref<HTMLAnchorElement>) => {
        const userName = getUsername() || '';
        const approvalsLink =
          linkTo === '/wfe/approvals' && `/wfe/approvals?approvers=${userName}`;
        const link = approvalsLink || linkTo || '';

        return (
          <Link
            ref={ref}
            to={link}
            {...linkProps}
            style={{ textDecoration: 'none' }}
          />
        );
      }),
    [linkTo],
  );

  const rootNode = linkTo ? CustomLink : null;

  const handleClick = () =>
    handleExpansion && handleExpansion(text, itemChildren, isSubmenuHeader);

  const expandCollapseIcons = () => {
    let IconComponent;

    if (isMainMenuItem) {
      IconComponent = open ? CollapseMainMenuIcon : ExpandMainMenuIcon;
    } else {
      IconComponent = open ? CollapseSubmenuIcon : ExpandSubmenuIcon;
    }

    return (
      <IconComponent
        className={cn(MenuStyles.MenuIcon, { [MenuStyles.HiddenIcon]: !open })}
        onClick={handleClick}
      />
    );
  };

  return (
    <div className={MenuStyles.MenuItem}>
      <StyledListItem
        key={text}
        onClick={handleClick}
        component={rootNode as ReactNode}
        open={open}
        isActive={isActive}
        isMainMenuItem={isMainMenuItem}
      >
        {iconSrc?.default && (
          <StyledListItemIcon
            className={cn(MenuStyles.IconContainer, 'ListItemIcon')}
          >
            <img
              alt="active menu icon"
              src={iconSrc.active}
              className={MenuStyles.Icon}
              style={{
                backgroundColor: themeStyles.color?.bg?.primary,
              }}
            />
            <img
              alt="menu icon"
              src={iconSrc.default}
              className={cn(
                MenuStyles.Icon,
                !open ? MenuStyles.TransparentIcon : MenuStyles.HiddenIcon,
              )}
              style={{
                backgroundColor: themeStyles.color?.bg?.primary,
              }}
            />
          </StyledListItemIcon>
        )}
        {!isMenuCollapsed && (
          <StyledListItemText
            primary={text}
            open={open}
            isActive={isActive}
            isMainMenuItem={isMainMenuItem}
            isSubmenuItem={isSubmenuItem}
          />
        )}
        {badgeCount > 0 && <SmallChip size="small" label={badgeCount} />}
        {children && expandCollapseIcons()}
      </StyledListItem>
      <Collapse
        component="li"
        in={open && !isMenuCollapsed}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </div>
  );
};
