import { MenuItemWithSearch } from '../../menu/types';

export function renameDefaultLinkToLinkTo(
  nodes: MenuItemWithSearch[],
): MenuItemWithSearch[] {
  return nodes.map(({ defaultLink, children, linkTo, ...restItem }) => ({
    ...restItem,
    linkTo: linkTo || defaultLink,
    ...(children ? { children: renameDefaultLinkToLinkTo(children) } : {}),
  }));
}
