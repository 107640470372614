import { createSlice } from '@reduxjs/toolkit';

import { FormState, SERVICES } from '../const';
import { TPayload } from '../types';

import { AuthState } from './types';

const initialState: AuthState = {
  currentSessionId: null,
  isAuthorized: false,
  error2fA: '',
  errorAuth: '',
  formState: FormState.AUTH,
  services: SERVICES.map((name) => ({
    name,
    tokenid: null,
    sessionid: null,
    status: null,
    type: null,
  })),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentSessionId: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentSessionId = action.payload;
    },
    setIsAuthorized: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isAuthorized = action.payload;
    },
    setFormState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.formState = action.payload;
    },
    setAuthError: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.errorAuth = action.payload;
    },
    set2FAError: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.error2fA = action.payload;
    },
    clearAllErrors: (state) => ({
      ...state,
      errorAuth: '',
      error2fA: '',
    }),
    setInitialState: (state) => ({
      ...state,
      ...initialState,
    }),
    setState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setServiceData: (state, action) => {
      const {
        name,
        sessionid,
        status,
        tokenid,
        type: tokenType,
      } = action.payload as TPayload;

      return {
        ...state,
        services: state.services.map((data) => {
          if (data.name === name) {
            return {
              ...data,
              sessionid: sessionid ?? null,
              status,
              tokenid: tokenid ?? null,
              type: tokenType ?? null,
            };
          }
          return data;
        }),
      };
    },
  },
});

export const {
  setCurrentSessionId,
  setIsAuthorized,
  setFormState,
  setServiceData,
  setAuthError,
  set2FAError,
  clearAllErrors,
  setState,
  setInitialState,
} = authSlice.actions;
