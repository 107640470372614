import { useTheme } from '@mui/material';
import {
  ChangeEventHandler,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { Loader, Notification, KitTheme } from 'react-ui-kit-exante';

import { useLocalStorage } from '../../../hooks/useLocalStorage';

import { LOCAL_STORAGE_KEY } from './CurrencySelector.consts';
import {
  prepareCurrenciesForSelect,
  createCurrencyChangeEvent,
} from './CurrencySelector.helpers';
import { StyledSelect, LoaderContainer } from './CurrencySelector.styled';
import { PreparedCurrency } from './CurrencySelector.types';
import { useFetchCurrencies } from './hooks/useFetchCurrencies';

let event: ReturnType<typeof createCurrencyChangeEvent> | null = null;

export const CurrencySelector = () => {
  const theme: KitTheme = useTheme();
  const { currencies, loading, error } = useFetchCurrencies();
  const { getItem, setItem } = useLocalStorage();
  const [currency, setCurrency] = useState('');

  const updateCurrency = (value: string) => {
    setCurrency(value);

    const currencyItem = currencies?.find(({ id }) => id === value);
    if (currencyItem) {
      setItem(LOCAL_STORAGE_KEY, JSON.stringify(currencyItem));
    }

    if (event !== null) {
      window.dispatchEvent(event);
    }
  };

  useEffect(() => {
    if (event === null) {
      event = createCurrencyChangeEvent();
    }
  }, []);

  useEffect(() => {
    if (error) {
      Notification.error({
        title: error?.message,
      });
    }
  }, [error]);

  const getInitialCurrency = useCallback(() => {
    const currentValue = getItem(LOCAL_STORAGE_KEY);
    if (currentValue) {
      try {
        const parsed: PreparedCurrency = JSON.parse(currentValue);
        const isExistedCurrency = currencies.some(
          ({ id }) => id === parsed?.id,
        );
        if (!isExistedCurrency) {
          return '';
        }
        return parsed?.id;
      } catch {
        return '';
      }
    }
    return '';
  }, [currencies]);

  const setFirstCurrencyFromList = useCallback(() => {
    if (currencies.length) {
      const defaultValue = currencies[0].id;
      updateCurrency(defaultValue);
    }
  }, [currencies]);

  useEffect(() => {
    const initialCurrency = getInitialCurrency();
    if (!initialCurrency) {
      setFirstCurrencyFromList();
    } else {
      updateCurrency(initialCurrency);
    }
  }, [currencies, setFirstCurrencyFromList]);

  const selectOptions = useMemo(
    () => (currencies ? prepareCurrenciesForSelect(currencies) : []),
    [currencies],
  );

  const handleChangeCurrency: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    updateCurrency(value);
  };

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (error) {
    return null;
  }

  const isDisabled = error || loading || !currencies.length;

  return (
    <StyledSelect
      value={currency}
      options={selectOptions}
      onChange={handleChangeCurrency}
      size="small"
      disabled={isDisabled}
      menuItemProps={{
        sx: {
          color: theme?.color?.typo?.ghost,
        },
      }}
      menuProps={{
        sx: {
          maxHeight: 400,
          '& .MuiPaper-root': {
            backgroundColor: theme?.color.bg.basic,
          },
          '& .MuiMenuItem-root:hover, .Mui-selected': {
            backgroundColor: theme?.color.bg.primary,
          },
        },
      }}
    />
  );
};
