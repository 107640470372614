import { useTheme } from '@mui/material/styles';
import { FC, useCallback, useState } from 'react';
import { KitTheme } from 'react-ui-kit-exante';

import { ReactComponent as SaveIcon } from '../../../../../../assets/i/Save.svg';
import { ReactComponent as SaveHoveredIcon } from '../../../../../../assets/i/SaveHovered.svg';

export const SaveSvg: FC<React.PropsWithChildren<{ className?: string }>> = ({
  className,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme: KitTheme = useTheme();

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseOver = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOver}>
      {isHovered ? (
        <SaveHoveredIcon
          className={className}
          stroke={theme?.color.typo.action}
        />
      ) : (
        <SaveIcon className={className} stroke={theme?.color.typo.action} />
      )}
    </span>
  );
};
