import { RequestGlobalSearchResponse } from '../../services/Types/nodeback.types';
import { ParseSearchReturn } from '../types';

const runTabByUrl: Record<string, string> = {
  'tab-all': '/crm/all',
  'tab-tab_clients': '/crm/tab_clients',
  'tab-data-change-requests': '/crm-ui/data-change-requests',
  'tab-deposits': '/crm-ui/deposits',
  'tab-monitoring': '/crm-ui/monitoring',
  'tab-withdrawals': '/crm-ui/withdrawals',
  'tab-contacts': '/crm-ui/contacts',
};

const nonCrmTabs = new Set([
  'tab-withdrawals',
  'tab-data-change-requests',
  'tab-monitoring',
  'tab-deposits',
]);

export function parseCrmSearch(
  { counts, data, metadata }: RequestGlobalSearchResponse['crm'],
  dict: Record<string, string>,
): ParseSearchReturn {
  const searchResult = data.map(({ id, link: subLink, text, tab }) => {
    const tabStartUrl = runTabByUrl[tab];

    let link: string;

    switch (true) {
      case nonCrmTabs.has(tab):
        link = `${tabStartUrl}/${id}`;
        break;
      case tab === 'tab-contacts':
        link = `/crm/contact-${id}`;
        break;
      default:
        link = `/crm/${subLink}`;
        break;
    }

    return {
      link,
      path: dict[tabStartUrl],
      text,
    };
  });

  const newCounts = Object.fromEntries(
    Object.entries(metadata?.menuCounts || counts).map(([tab, count]) => [
      runTabByUrl[tab],
      count,
    ]),
  );

  return { searchResult, newCounts };
}
