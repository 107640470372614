import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { UAParser } from 'ua-parser-js';

import { version } from '../../../shared/constants/Various.constant';

import { TLocalDeviceData } from './types';

export const APP_NAME = 'Run UI';

export const collectSessionData = (): TLocalDeviceData => {
  const applicationVersion = version || 'Unknown';

  const parser = new UAParser();
  const { device, os } = parser.getResult();

  const deviceName = [device?.vendor, device?.model]
    .filter((value) => value)
    .join(' ');

  const osName = !os?.name
    ? null
    : [os.name, os.version].filter((value) => value).join(' ');

  return {
    applicationName: APP_NAME,
    applicationVersion,
    deviceName,
    osName,
  };
};

export const collectSessionHeaders = async (): Promise<
  Record<string, string>
> => {
  const deviceFingerprint = (await getFingerprint()) as string;

  const { applicationName, applicationVersion, deviceName, osName } =
    await collectSessionData();

  return {
    'x-application-name': applicationName,
    'x-application-version': applicationVersion,
    'x-device-fingerprint': deviceFingerprint,
    ...(deviceName && { 'x-device-name': deviceName }),
    ...(osName && { 'x-os-name': osName }),
  };
};
