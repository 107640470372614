import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { IAccessToken, TAuthTokenPayload } from '../auth/types';

import { collectSessionHeaders } from './Api/collectSessionData';

export const AUTH_API_REDUCER_KEY = 'authApi';

export const authApi = createApi({
  reducerPath: 'AUTH_API_REDUCER_KEY',
  baseQuery: (...props) => {
    const [args] = props;
    const { baseUrl } = args;

    return fetchBaseQuery({
      baseUrl,
    })(...props);
  },
  endpoints: (builder) => ({
    getTokens: builder.query<
      IAccessToken,
      TAuthTokenPayload & { baseUrl: string }
    >({
      queryFn: async ({ baseUrl, code }, _, __, fetchWithBaseQuery) => {
        try {
          const sessionHeaders = await collectSessionHeaders();

          const response = await fetchWithBaseQuery({
            baseUrl,
            url: `oauth/code?code=${code}`,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              ...sessionHeaders,
            },
            method: 'GET',
          });

          const data = response.data as IAccessToken;

          return {
            data,
          };
        } catch (error) {
          return {
            error: error as FetchBaseQueryError,
          };
        }
      },
    }),
  }),
});

export const { useGetTokensQuery } = authApi;
