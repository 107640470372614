import { format, parse } from 'date-fns';
import Hashes from 'jshashes';
import { capitalize } from 'lodash';
import { useMemo } from 'react';

import { useApi, Service } from '../../config/useApi';
import { MenuItem } from '../menu/types';

import { DATE_FORMAT, DATE_TIME_FORMAT } from './const';
import { TModules } from './types';

export function transformVariantsToSelectOptions(
  variants: string[] = [],
  capitalized = false,
) {
  if (!variants.length) {
    return [];
  }
  return variants.map((type) => ({
    label: capitalized ? capitalize(type) : type,
    value: type,
  }));
}

export function getReleasePostfix() {
  return window.location.pathname.includes('release') ? '/release' : '';
}

export const useScriptInject = () => {
  const { getApiUrl } = useApi();
  const releasePostfix = getReleasePostfix();

  const bundleScripts = useMemo(
    () => ({
      symboldb: `${getApiUrl(Service.SymbolDB)}/editor/assets/js/script.js`,
      symboldbui: `${getApiUrl(
        Service.SymbolDBUI,
      )}${releasePostfix}/static/js/main.js`,
      ordermanagement: `${getApiUrl(
        Service.OrderManagementUI,
      )}${releasePostfix}/static/js/main.js`,
      recon: `${getApiUrl(Service.Recon)}/static/js/main.js`,
      stocklending: `${getApiUrl(Service.StockLending)}/static/js/main.js`,
      webbo: `${getApiUrl(Service.WebBO)}${releasePostfix}/static/js/main.js`,
      ns: `${getApiUrl(Service.NS)}${releasePostfix}/static/js/main.js`,
      workflow: `${getApiUrl(
        Service.WorkflowUI,
      )}${releasePostfix}/wfe/static/js/main.js`,
      reporting: `${getApiUrl(
        Service.Reporting,
      )}${releasePostfix}/static/js/main.js`,
      crm: `${getApiUrl(Service.Crm)}/static/js/main.js`,
      crmui: `${getApiUrl(Service.CrmUIReact)}/static/js/main.js`,
      cprm: `${getApiUrl(Service.Cprm)}/static/js/main.js`,
      cprmui: `${getApiUrl(Service.CprmUIReact)}/static/js/main.js`,
      cas: `${getApiUrl(Service.CAS)}${releasePostfix}/static/js/main.js`,
      branding: `${getApiUrl(Service.Branding)}/static/js/main.js`,
      remus: `${getApiUrl(Service.RemusUI)}/static/js/main.js`,
      stresstest: `${getApiUrl(Service.StressTestUI)}/static/js/main.js`,
      partneradmin: `${getApiUrl(
        Service.PartnerAdminUI,
      )}${releasePostfix}/static/js/main.js`,
    }),
    [getApiUrl],
  );

  const handleScriptInject = (
    { scriptTags }: { scriptTags: HTMLScriptElement[] },
    moduleName: keyof typeof bundleScripts,
    cb: () => void,
  ) => {
    if (scriptTags) {
      const scriptTag = scriptTags.find(
        (script) => script.src === bundleScripts[moduleName],
      );
      if (scriptTag && cb) {
        scriptTag.addEventListener('load', () => cb());
      }
    }
  };

  return {
    handleScriptInject,
  };
};

export const isVPNModule = (moduleName: TModules, menuConfig: any) =>
  menuConfig.find((item: { text: string }) => item.text === moduleName)?.vpn;

export const formatDate = (date: Date | null, time?: boolean) =>
  date ? format(date, time ? DATE_TIME_FORMAT : DATE_FORMAT) : '';

export const parseDate = (date: string) => parse(date, DATE_FORMAT, new Date());

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const checkIsValidIsoString = (str: string) => {
  const dateParsed = Date.parse(str);

  if (Number.isNaN(dateParsed)) {
    return false;
  }
  const date = new Date(dateParsed);

  return date.toISOString() === str;
};

export const isSdbInnerModule = (item: MenuItem, openedItemLink: string) =>
  !!item.linkTo &&
  item.linkTo.includes('symboldb') &&
  openedItemLink.includes(item.linkTo);

export const getStringHash = (str: string) => {
  return new Hashes.MD5().hex(str);
};
