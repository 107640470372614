import { MenuItemWithSearch } from '../../menu/types';

/**
 * Updates the count of parent nodes to be the sum of the counts of their direct descendants.
 *
 * @param {Object[]} searchResult - The array of nodes representing the tree structure.
 * @param {number} [searchResult[].count] - The count of the node.
 */
export function setCountForParentNodes(searchResult: MenuItemWithSearch[]) {
  if (!Array.isArray(searchResult)) {
    return;
  }

  /**
   * Helper function to recursively calculate counts for each node.
   *
   * @param {Object} node - The current node being processed.
   * @return {number} - The sum of the counts for the current node and its descendants.
   */
  function calculateCountForNode(node: MenuItemWithSearch) {
    // If the node has no children, return its count or 0 if undefined.
    if (!node.children || node.children.length === 0) {
      return node.count || 0;
    }

    let sumCount = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const child of node.children) {
      sumCount += calculateCountForNode(child);
    }

    // eslint-disable-next-line no-param-reassign
    node.count = sumCount;
    return sumCount;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const node of searchResult) {
    calculateCountForNode(node);
  }
}
