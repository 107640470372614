import { AuthLayout, AuthLogin } from '../auth';
import Branding from '../branding/Branding';
import CorporateActions from '../corporate-actions/CorporateActions';
import Cprm from '../cprm/Cprm';
import CprmReact from '../cprm2.0/Cprm2.0';
import Crm from '../crm/Crm';
import CrmReact from '../crm2.0/Crm2.0';
import { Dashboard } from '../dashboard/Dashboard';
import CprmDjangoAdmin from '../django-admin/CPRM-django-admin';
import CrmDjangoAdmin from '../django-admin/CRM-django-admin';
import NotificationsShaper from '../notifications-shaper/NotificationsShaper';
import OrderManagement from '../order-management/OrderManagement';
import PartnerAdmin from '../partner-admin/PartnerAdmin';
import Profile from '../profile/Profile';
import Recon from '../recon/Recon';
import Remus from '../remus/Remus';
import Reporting from '../reporting/Reporting';
import StockLending from '../stock-lending/StockLending';
import StressTest from '../stress-test/StressTest';
import SymbolDB from '../symbol-db/SymbolDB';
import SymbolDBUI from '../symboldb-ui-react/SymbolDBUI';
import WebBO from '../webbo/WebBO';
import Workflow from '../workflow/Workflow';

import { IRoute, Routes } from './types';

export const RouteList: Array<IRoute> = [
  {
    path: Routes.AUTH,
    component: AuthLogin,
    layout: AuthLayout,
    isNotPrivate: true,
  },
  { path: Routes.MODULE_AUTH, component: AuthLogin, layout: AuthLayout },
  {
    path: Routes.WFE,
    component: Workflow,
    name: 'Workflow',
  },
  {
    path: Routes.RECON,
    component: Recon,
    name: 'Recon',
  },
  {
    path: Routes.WEBBO,
    component: WebBO,
    name: 'webbo',
  },
  { path: Routes.SYMBOLDB, component: SymbolDB },
  {
    path: Routes.SYMBOLDB_THEMES,
    component: SymbolDBUI,
    name: 'Themes',
  },
  {
    path: Routes.PROFILE,
    component: Profile,
    name: 'Profile',
  },
  {
    path: Routes.CORPORATE_ACTIONS,
    component: CorporateActions,
    name: 'Corporate Actions',
  },
  {
    path: Routes.STOCK_LENDING,
    component: StockLending,
    name: 'Stock Lending',
  },
  {
    path: Routes.NOTIFICATIONS_SHAPER,
    component: NotificationsShaper,
    name: 'Templates',
  },
  { path: Routes.CRM, component: Crm },
  { path: Routes.CPRM, component: Cprm },
  { path: Routes.CRM_UI_REACT, component: CrmReact },
  { path: Routes.CPRM_UI_REACT, component: CprmReact },
  {
    path: Routes.SYMBOLDB_UI,
    component: SymbolDBUI,
    name: 'SymbolDB UI',
  },
  {
    path: Routes.ORDER_MANAGEMENT,
    component: OrderManagement,
    name: 'Order Management UI',
  },
  {
    path: Routes.REPORTING,
    component: Reporting,
    name: 'Reporting UI',
  },
  {
    path: Routes.DASHBOARD,
    component: Dashboard,
    name: 'Dashboard',
  },
  {
    path: Routes.BRANDING,
    component: Branding,
    name: 'Branding',
  },
  {
    path: Routes.REMUS,
    component: Remus,
    name: 'Remus',
  },
  {
    path: Routes.STRESS_TEST,
    component: StressTest,
    name: 'Stress Test',
  },
  {
    path: Routes.СRM_ADMIN,
    component: CrmDjangoAdmin,
    name: 'CRM Admin',
  },
  {
    path: Routes.CPRM_ADMIN,
    component: CprmDjangoAdmin,
    name: 'CPRM Admin',
  },
  {
    path: Routes.PARTNER_ADMIN,
    component: PartnerAdmin,
    name: 'Partner Admin',
  },
];
