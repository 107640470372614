import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAppSelector } from '../../store/hooks';
import { AuthSelector } from '../auth/selectors';

import { Routes } from './types';

export const PrivateRoute: FC<RouteProps> = (props) => {
  const { isAuthorized } = useAppSelector(AuthSelector);
  const { path, exact, children } = props;

  if (isAuthorized) {
    return (
      <Route path={path} exact={exact}>
        {children}
      </Route>
    );
  }

  return <Redirect to={{ pathname: Routes.AUTH }} />;
};
