import { RequestGlobalSearchResponse } from '../../services/Types/nodeback.types';
import { ParseSearchReturn } from '../types';

const runTabByUrl: Record<string, string> = {
  'tab-all': '/cprm/all',
  'tab-tab_clients': '/cprm/tab_clients',
};

export function parseCprmSearch(
  { counts, data, metadata }: RequestGlobalSearchResponse['cprm'],
  dict: Record<string, string>,
): ParseSearchReturn {
  const searchResult = data.map(({ link: subLink, text, tab }) => ({
    link: `/cprm/${subLink}`,
    path: dict[runTabByUrl[tab]],
    text,
  }));

  const newCounts = Object.fromEntries(
    Object.entries(metadata?.menuCounts || counts).map(([tab, count]) => [
      runTabByUrl[tab],
      count,
    ]),
  );

  return { searchResult, newCounts };
}
