import { MenuItemWithSearch } from '../../menu/types';

// We need to change the order of some menu items
// https://confluence.exante.eu/display/DevDoc/Global+Search
export function reorderMenu(searchData: MenuItemWithSearch[]) {
  const reorderedSearData: MenuItemWithSearch[] = structuredClone(searchData);

  const clientManagementIndex = reorderedSearData.findIndex(
    (item) => item?.defaultLink === '/crm-ui/contacts',
  );

  if (clientManagementIndex !== -1) {
    let clientManagementSubMenu: MenuItemWithSearch[] =
      structuredClone(reorderedSearData[clientManagementIndex].children) || [];

    // move All Applications on first place
    const allApplicationsIndex = clientManagementSubMenu.findIndex(
      (item) => item.crmId === 'crm-tab-all',
    );

    if (allApplicationsIndex !== -1) {
      const [item] = clientManagementSubMenu.splice(allApplicationsIndex, 1);
      clientManagementSubMenu.unshift(item);
    }

    // move BackOffice after All Applications
    const boItems = clientManagementSubMenu.filter((item) =>
      item.linkTo?.startsWith('/bo'),
    );
    clientManagementSubMenu = clientManagementSubMenu.filter(
      (item) => !item.linkTo?.startsWith('/bo'),
    );

    const insertIndex =
      clientManagementSubMenu.findIndex(
        (item) => item.crmId === 'crm-tab-all',
      ) + 1 || 0;

    clientManagementSubMenu.splice(insertIndex, 0, ...boItems);

    reorderedSearData[clientManagementIndex].children = clientManagementSubMenu;
  }

  return reorderedSearData;
}
