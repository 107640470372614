import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useApi, Service } from '../../config/useApi';
import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { getReleasePostfix, useScriptInject } from '../shared/helpers';

let alreadyLoaded = false;

const Reporting = () => {
  const [loaded, setLoaded] = useState(alreadyLoaded);
  const { handleScriptInject } = useScriptInject();
  const { getApiUrl } = useApi();
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('reporting-bundle');

  setStartHandleTime();

  useEffect(() => {
    if (loaded && window.REG_REPORTING_UI) {
      window.REG_REPORTING_UI.render(document.getElementById('reporting'));
    }
    return () => {
      window.REG_REPORTING_UI?.root?.unmount();
    };
  }, [loaded]);

  const handleScriptInjectCB = useCallback((_newState: any, addedTags: any) => {
    handleScriptInject(addedTags, toLowerCase(MODULES.REPORTING), () => {
      setLoaded(true);
      logHandleTime();
      alreadyLoaded = true;
      if (window.REG_REPORTING_UI) {
        window.REG_REPORTING_UI.render(document.getElementById('reporting'));
      }
    });
  }, []);

  const baseUrl = getApiUrl(Service.Reporting);
  const releasePostfix = useMemo(() => getReleasePostfix(), []);

  return (
    <>
      <Helmet
        script={
          loaded
            ? []
            : [
                {
                  type: 'module',
                  src: `${baseUrl}${releasePostfix}/static/js/main.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href={`${baseUrl}${releasePostfix}/static/css/main.css`}
        />
      </Helmet>
      <div id="reporting" className="Reporting" />
    </>
  );
};

export default Reporting;
