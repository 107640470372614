import { useMemo, useState } from 'react';

import { useNodeBackService } from '../components/services/NodeBack.service';
import {
  TBOMenuItem,
  TCrmBookmarksData,
} from '../components/services/Types/bookmarks.types';

import { getAddedBookmarkTabs, getBookmarksDistributedByTabs } from './helpers';
import { useMenuConfig } from './useMenuConfig';

let cachedBOBookmarksData: TCrmBookmarksData = null;

export const useBOBookmarks = () => {
  const { getBookmarks } = useNodeBackService();
  const { getMenuConfig } = useMenuConfig();

  const menuConfig = getMenuConfig();

  const [boBookmarksState, setBOBookmarksStateState] =
    useState<TBOMenuItem[]>();

  const getBOBookmarkData = async (forceUpdate = false) => {
    if (!cachedBOBookmarksData || forceUpdate) {
      cachedBOBookmarksData = await getBookmarks();
    }
    return cachedBOBookmarksData;
  };

  const request = async (forceUpdate = false) => {
    try {
      const resp = await getBOBookmarkData(forceUpdate);
      if (resp && resp.data) {
        const addedTabs = getAddedBookmarkTabs(resp.data, menuConfig, 'bo');
        setBOBookmarksStateState(
          getBookmarksDistributedByTabs(addedTabs, 'bo'),
        );
      }
    } catch (e) {
      console.error('BO bookmarks error', e);
    }
  };

  return useMemo(
    () => ({
      boBookmarks: boBookmarksState,
      requestBoBookmarks: request,
    }),
    [boBookmarksState],
  );
};
