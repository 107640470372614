import { useTheme } from '@mui/material/styles';
import { FC, useCallback, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { KitTheme } from 'react-ui-kit-exante';

const lines = ['M12 20H13', 'M11 20H20'];

const pencils = [
  'M16.5 3.50001C16.8978 3.10219 17.4374 2.87869 18 2.87869C18.2786 2.87869 18.5544 2.93356 18.8118 3.04017C19.0692 3.14677 19.303 3.30303 19.5 3.50001C19.697 3.697 19.8532 3.93085 19.9598 4.18822C20.0665 4.44559 20.1213 4.72144 20.1213 5.00001C20.1213 5.27859 20.0665 5.55444 19.9598 5.81181C19.8532 6.06918 19.697 6.30303 19.5 6.50001L7 19L3 20L4 16L16.5 3.50001Z',
  'M14.5 3.49998C14.8978 3.10216 15.4374 2.87866 16 2.87866C16.2786 2.87866 16.5544 2.93353 16.8118 3.04014C17.0692 3.14674 17.303 3.303 17.5 3.49998C17.697 3.69697 17.8532 3.93082 17.9598 4.18819C18.0665 4.44556 18.1213 4.72141 18.1213 4.99998C18.1213 5.27856 18.0665 5.55441 17.9598 5.81178C17.8532 6.06915 17.697 6.303 17.5 6.49998L5 19L1 20L2 16L14.5 3.49998Z',
];

export const EditSvg: FC<
  React.PropsWithChildren<{ className: string; dataTestId: string }>
> = ({ className, dataTestId }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const theme: KitTheme = useTheme();

  const lineColors = useMemo(
    () => [theme?.color.typo.action, theme?.color.typo.primary],
    [theme],
  );

  const animationProps = useSpring({
    line: lines[activeIndex],
    lineColor: lineColors[activeIndex],
    pencil: pencils[activeIndex],
  });

  const handleMouseEnter = useCallback(() => {
    setActiveIndex(1);
  }, []);

  const handleMouseOver = useCallback(() => {
    setActiveIndex(0);
  }, []);

  return (
    <svg
      className={className}
      data-test-id={dataTestId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOver}
    >
      <animated.path
        d={animationProps.pencil}
        stroke={animationProps.lineColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.path
        d={animationProps.line}
        stroke={animationProps.lineColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
