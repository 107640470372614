import { RequestGlobalSearchResponse } from '../../services/Types/nodeback.types';
import { ParseSearchReturn } from '../types';

export function parseNodeBackSearch(
  { data, counts, metadata }: RequestGlobalSearchResponse['backoffice'],
  dict: Record<string, string>,
): ParseSearchReturn {
  const searchResult = data.map(({ id, text, tab }) => {
    return {
      link: `/bo/${tab}/${id}`,
      path: dict[`/bo/${tab}`],
      text,
    };
  });

  const newCounts = Object.fromEntries(
    Object.entries(metadata?.menuCounts || counts).map(([tab, count]) => [
      `/bo/${tab}`,
      count,
    ]),
  );

  return { searchResult, newCounts };
}
