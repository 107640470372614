import { MenuItemWithSearch } from '../../menu/types';

export function addContextSearch(
  nodes: MenuItemWithSearch[],
  searchValue: string,
) {
  const boRegex = new RegExp(
    `^(${['/bo/accounts', '/bo/clients', '/bo/users'].join('|')})`,
  );
  const crmRegex = new RegExp(
    `^(${['/crm-ui/contacts', '/crm-ui/withdrawals', '/crm-ui/deposits'].join(
      '|',
    )})`,
  );

  const newNodes: MenuItemWithSearch[] = structuredClone(nodes);

  newNodes.forEach((node) => {
    let queryParamName = '';

    if (node.search?.length && boRegex.test(node.linkTo || '')) {
      queryParamName = 'contextSearch';
    }

    if (node.search?.length && crmRegex.test(node.linkTo || '')) {
      queryParamName = 'search';
    }

    if (queryParamName) {
      let newLinkTo = node.linkTo || '';

      const url = new URL(newLinkTo, window.location.origin);
      url.searchParams.set(queryParamName, searchValue);
      newLinkTo = url.pathname + url.search;

      // eslint-disable-next-line no-param-reassign
      node.linkTo = newLinkTo;
    }

    if (node.children?.length) {
      // eslint-disable-next-line no-param-reassign
      node.children = addContextSearch(node.children, searchValue);
    }
  });

  return newNodes;
}
