import { getAccessToken } from '../services/Cookies.service';

type TOptionsHeaders = {
  Accept: string;
  Authorization?: string;
  'Content-Type': string;
};

type TOptions = {
  method: string;
  headers: TOptionsHeaders;
};

export const getRequest = (url: string, accessToken?: string) => {
  const BASE_URL = `${window.SYMBOLDB_UI_REACT?.api_urls?.SYMBOLDB_EDITOR}/api/v1.0`;
  const options: TOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }

  return fetch(`https://${BASE_URL}${url}`, options);
};

export const fetchUpdatesQueueTasks = (
  setCountUpdatesQueueTasks: (countUpdatesQueueTasks: number | null) => void,
) => {
  const token = getAccessToken();
  const accessToken = token?.access_token;
  const url = '/tasks';

  if (accessToken) {
    getRequest(url, accessToken)
      .then((response) => {
        if (!response.ok) {
          console.error(`Status code: ${response.status}`);

          return 0;
        }

        return response.json();
      })
      .then((data) => setCountUpdatesQueueTasks(data?.length));
  }
};
