const hostname = window.location.hostname;

export const isDemo = hostname.includes('demo');
export const isLocalhost = hostname.includes('localhost');
export const isPreprod = hostname.includes('preprod');
export const isStage = hostname.includes('stage');
export const isTest = hostname.includes('test');
export const isProd = !isStage && !isLocalhost && !isDemo && !isTest;

export const CRM_UI_SERVICE_URL_PREFIX = 'crm-ui';
export const CPRM_UI_SERVICE_URL_PREFIX = 'cprm-ui';
