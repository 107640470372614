import { useCallback, useMemo } from 'react';

import { setMenuConfig } from '../components/menu/reducer';
import { MenuConfigSelector } from '../components/menu/selectors';
import { useFavourites } from '../components/menu/useFavourites';
import { useNodeBackService } from '../components/services/NodeBack.service';
import { useAppDispatch, useAppSelector } from '../store/hooks';

import { getMenuWithCrm, getMenuWithFavourites } from './helpers';
import { useCrmsConfig } from './useCrmsConfig';

export const useMenuConfig = () => {
  const menuConfig = useAppSelector(MenuConfigSelector);
  const dispatch = useAppDispatch();

  const { crmsMenu } = useCrmsConfig();
  const { getMenu } = useNodeBackService();
  const { favourites } = useFavourites();

  const initializeMenu = useCallback(async (accessToken: string) => {
    const nodeBackMenu = await getMenu(accessToken);
    return dispatch(setMenuConfig(nodeBackMenu));
  }, []);

  const injectedCrmToMenu = useMemo(
    () => getMenuWithCrm(menuConfig, crmsMenu),
    [menuConfig, crmsMenu],
  );

  const injectedFavouritesToMenu = useMemo(
    () => getMenuWithFavourites(injectedCrmToMenu, favourites),
    [injectedCrmToMenu, favourites],
  );

  return {
    initializeMenu,
    getMenuConfig: () => injectedFavouritesToMenu,
    isCrmsMenu: crmsMenu.length !== 0,
  };
};
