import { AxiosResponse, CancelTokenSource } from 'axios';

import { useApi } from '../../config/useApi';
import { AUTH_SERVICE } from '../auth/const';

import {
  apiService,
  createInstance,
  TOptions,
  updateInstanceToken,
} from './Api/Api.service';
import { Endpoint } from './Api/constants';
import { getAccessToken } from './Cookies.service';
import { IBOBookmarkItem } from './Types/bookmarks.types';
import { ICurrencyResponse } from './Types/currencies.types';
import { RequestGlobalSearchResponse } from './Types/nodeback.types';

const nodeBackInstance = createInstance(AUTH_SERVICE);

export const useNodeBackService = () => {
  const { getNodeBackUrl } = useApi();
  const nodeBackAPI = apiService(getNodeBackUrl(), nodeBackInstance);
  const token = getAccessToken();
  updateInstanceToken(nodeBackInstance, token);

  const getBookmarks = async () => {
    const response: AxiosResponse<IBOBookmarkItem[]> = await nodeBackAPI.get({
      endPoint: `${Endpoint.Bookmarks}`,
      params: {
        isMenuItems: true,
      },
    });

    return response;
  };

  const getCurrencies = async () => {
    const result: AxiosResponse<ICurrencyResponse> = await nodeBackAPI.get({
      endPoint: `${Endpoint.Currencies}`,
    });

    const { values } = result.data;
    return values;
  };

  const registerSession = async (accessToken?: string) => {
    let config: TOptions = {
      endPoint: '/register-session',
    };
    if (accessToken) {
      config = {
        ...config,
        accessToken,
      };
    }
    const result: AxiosResponse<any> = await nodeBackAPI.get(config);

    return result.data;
  };

  const getMenu = async (accessToken?: string) => {
    let config: TOptions = {
      endPoint: '/menu',
    };
    if (accessToken) {
      config = {
        ...config,
        accessToken,
      };
    }

    const result: AxiosResponse<any> = await nodeBackAPI.get(config);

    return result.data;
  };

  const fetchLogHandleTime = async (data: {
    entity: string;
    start: string;
    end: string;
  }) => {
    const config: TOptions = {
      endPoint: '/influx-proxy/log-handle-time',
    };

    const result: AxiosResponse<any> = await nodeBackAPI.post({
      ...config,
      data,
    });

    return result.data;
  };

  const requestGlobalSearch = async (
    value: string,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<RequestGlobalSearchResponse>> => {
    return nodeBackAPI.get({
      endPoint: `/search/computed?query=${encodeURIComponent(value)}`,
      cancelToken: cancelToken.token,
    });
  };

  return {
    fetchLogHandleTime,
    getBookmarks,
    getCurrencies,
    getMenu,
    registerSession,
    requestGlobalSearch,
  };
};
