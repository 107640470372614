import { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { ReactComponent as CollapsedMenuLogoIcon } from '../../assets/i/CollapsedMenuLogo.svg';
import { ReactComponent as MenuLogoIcon } from '../../assets/i/MenuLogo.svg';
import { ReactComponent as CollapseIcon } from '../../assets/i/chevrons-up.svg';
import { ConfigContext } from '../../config/ConfigContext';
import { MenuItem as MenuItemType, MenuConfig } from '../../config/types';
import { useMenuConfig } from '../../config/useMenuConfig';
import { useInterval } from '../../hooks/useInterval';
import { POLLING_DELAY } from '../../shared/const';
import { isExante } from '../../shared/helpers/routingHelpers';
import { useAppSelector } from '../../store/hooks';
import { Service } from '../auth/const';
import { useServices } from '../auth/hooks/useServices';
import { GlobalSearchBadgesSelector } from '../globalSearch/selectors';
import { fetchUpdatesQueueTasks } from '../symboldb-ui-react/requests';
import { fetchApprovals } from '../workflow/requests';

import MenuStyles from './Menu.module.css';
import {
  CollapseMenuButton,
  StyledMenu,
  StyledMenuWrapper,
  StyledMenuList,
  StyledMenuItem,
} from './Menu.styled';
import { MenuItem } from './MenuItem';
import { Submenu } from './components/Submenu';
import { MENU_ITEMS_WITH_CHIP } from './const';
import {
  findActiveItems,
  getBadgeCount,
  getFormattedItemText,
  renderChip,
} from './helpers';
import { MenuProps, ExpandedKey, ExpandedValue } from './types';

const getActiveLinks = (
  openedItemLink: string,
  menuConfig: MenuConfig,
): string[] =>
  menuConfig.flatMap((i: MenuItemType) => findActiveItems(i, openedItemLink));

const getExpandedItems = (activeLinks: string[], menuConfig: MenuConfig) => {
  const items: Record<string, ExpandedValue> = {};

  activeLinks.forEach((link) => {
    const isTopLevel = menuConfig.some(
      ({ text }: MenuItemType) => text === link,
    );

    items[link] = {
      isVisible: isTopLevel,
      isSubmenu: !isTopLevel,
    };
  });

  return items;
};

export const Menu = ({
  isCollapsed: isMenuCollapsed,
  handleCollapseMenu,
}: MenuProps) => {
  const menuBadges = useAppSelector(GlobalSearchBadgesSelector);
  const { hash, pathname } = useLocation();
  const openedItemLink = pathname + hash;
  const { getMenuConfig } = useMenuConfig();
  const menuConfig = getMenuConfig();
  const workflowApi = window.WORKFLOW_UI?.api_urls?.WORKFLOW;
  const symbolDBReactApi = window.SYMBOLDB_UI_REACT?.api_urls?.SYMBOLDB_EDITOR;
  const { allowedServices } = useServices();
  const { vars } = useContext(ConfigContext);

  const isAllowedWfe = useMemo(
    () =>
      allowedServices.filter(
        (item: { name: string }) => item.name === Service.Workflow,
      ).length > 0,
    [allowedServices],
  );

  const isAllowedSDB = useMemo(
    () =>
      allowedServices.filter(
        (item: { name: string }) => item.name === Service.Symboldb,
      ).length > 0,
    [allowedServices],
  );

  const [activeLinks, setActiveLinks] = useState<string[]>(() =>
    getActiveLinks(openedItemLink, menuConfig),
  );
  const [expandedItems, setExpandedItems] = useState(() =>
    getExpandedItems(activeLinks, menuConfig),
  );
  const [countApprovals, setCountApprovals] = useState<number | null>(null);
  const [countUpdatesQueueTasks, setUpdatesQueueTasks] = useState<
    number | null
  >(null);

  const handleCloseParent = (
    parentItem: MenuItemType | undefined,
    isClosable: boolean | undefined,
  ) => {
    if (!parentItem || !isClosable) {
      return;
    }
    const { text: parentItemName } = parentItem;
    const newItems = {
      ...expandedItems,
      [parentItemName]: {
        ...expandedItems[parentItemName],
        isVisible: false,
      },
    };

    setExpandedItems(newItems);
  };

  const isActiveLinkItem = (name: MenuItemType['text']) =>
    activeLinks.includes(name);

  const handleExpansion = (
    itemName: string,
    children: MenuItemType[],
    isSubmenuHeader = false,
  ) => {
    const currentValue: ExpandedValue = expandedItems[itemName as ExpandedKey];
    const newItems = {
      ...expandedItems,
      [itemName]: {
        isVisible: currentValue
          ? !currentValue?.isVisible
          : !isActiveLinkItem(itemName),
        isSubmenu: isSubmenuHeader,
      },
    };

    if (isSubmenuHeader) {
      Object.keys(newItems).forEach((key) => {
        const item = newItems[key];
        if (key !== itemName && item?.isSubmenu && item?.isVisible) {
          item.isVisible = false;
        }
      });
    }
    if (!newItems[itemName].isVisible) {
      setActiveLinks(activeLinks.filter((link) => link !== itemName));
    } else {
      setActiveLinks([...activeLinks, itemName]);
    }
    setExpandedItems(newItems);
  };

  useEffect(() => {
    const links = menuConfig.flatMap((i: MenuItemType) =>
      findActiveItems(i, openedItemLink),
    );
    setActiveLinks(links);
  }, [openedItemLink]);

  /**
   * Workflow UI
   */
  useEffect(() => {
    if (workflowApi && isAllowedWfe) {
      fetchApprovals(setCountApprovals);
    }
  }, [workflowApi, isAllowedWfe]);

  useInterval(
    () => isAllowedWfe && fetchApprovals(setCountApprovals),
    POLLING_DELAY,
  );

  /**
   * SymbolDB UI
   */
  useEffect(() => {
    if (symbolDBReactApi && isAllowedSDB) {
      fetchUpdatesQueueTasks(setUpdatesQueueTasks);
    }
  }, [symbolDBReactApi, isAllowedSDB]);

  useInterval(
    () => isAllowedSDB && fetchUpdatesQueueTasks(setUpdatesQueueTasks),
    POLLING_DELAY,
  );

  const isExpanded = (item: MenuItemType): boolean => {
    const value: ExpandedValue = expandedItems[item.text as ExpandedKey];
    return !!value?.isVisible;
  };

  const renderMenuItem = (
    item: MenuItemType,
    parent?: MenuItemType,
    isSubmenuItem?: boolean,
  ) => {
    if (item.children) {
      return (
        <div
          key={item.text}
          data-test-id={`menu-module__menu-item${getFormattedItemText(
            item,
            parent,
          )}`}
        >
          <MenuItem
            text={item.text}
            iconSrc={item.iconSrc}
            handleExpansion={handleExpansion}
            isSubmenuItem={isSubmenuItem}
            isSubmenuHeader={item.isSubmenuHeader}
            open={isExpanded(item)}
            itemChildren={item.children}
            isMenuCollapsed={isMenuCollapsed}
            badgeCount={getBadgeCount(item, menuBadges)}
          >
            {item.isSubmenuHeader ? (
              <Submenu item={item} renderMenuItem={renderMenuItem} />
            ) : (
              <StyledMenuList>
                {item.children.map((child: MenuItemType) => (
                  <div key={child.text}>{renderMenuItem(child, item)}</div>
                ))}
              </StyledMenuList>
            )}
          </MenuItem>
        </div>
      );
    }

    const closeParent = () => handleCloseParent(parent, isSubmenuItem);

    if (!item.text) {
      return null;
    }

    return (
      <StyledMenuItem
        hasChip={MENU_ITEMS_WITH_CHIP.includes(item.text)}
        key={item.text}
        linkTo={item.linkTo}
        onClick={closeParent}
        role="button"
        tabIndex={0}
        data-test-id={`menu-module__menu-item${getFormattedItemText(
          item,
          parent,
        )}`}
      >
        <MenuItem
          text={item.text}
          linkTo={item.linkTo}
          iconSrc={item.iconSrc}
          isSubmenuItem={isSubmenuItem}
          isMenuCollapsed={isMenuCollapsed}
          badgeCount={getBadgeCount(item, menuBadges)}
        />
        {renderChip(item, countApprovals, countUpdatesQueueTasks)}
      </StyledMenuItem>
    );
  };

  const logo = isMenuCollapsed ? (
    <div className={MenuStyles.LogoCollapsed}>
      <Link to={vars?.HOME_PATH}>
        <CollapsedMenuLogoIcon />
      </Link>
    </div>
  ) : (
    <div className={MenuStyles.Logo}>
      <Link to={vars?.HOME_PATH}>
        <MenuLogoIcon />
      </Link>
    </div>
  );

  return (
    <StyledMenuWrapper isCollapsed={isMenuCollapsed} className="MenuWrapper">
      <StyledMenu isCollapsed={isMenuCollapsed} className="Menu">
        {isExante() && logo}
        <StyledMenuList>
          {menuConfig.map((menuItem: MenuItemType) => renderMenuItem(menuItem))}
        </StyledMenuList>
        <CollapseMenuButton
          isCollapsed={isMenuCollapsed}
          onClick={handleCollapseMenu}
          className="CollapseMenuButton"
        >
          <CollapseIcon className="CollapseIcon" />
        </CollapseMenuButton>
      </StyledMenu>
    </StyledMenuWrapper>
  );
};
