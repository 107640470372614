import { capitalize } from 'lodash';

import { isLocalhost } from '../../config/const';
import * as defaultBrandingEnv from '../constants/branding-env-configs/branding-env-default.config.json';

import { BrandingEnvConfig } from './types';

export const setFavicon = (faviconLink: string) => {
  let link = document.querySelector("link[rel~='icon']") as
    | HTMLLinkElement
    | undefined;

  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';

    document.getElementsByTagName('head')[0].appendChild(link);
  }

  link.href = faviconLink;
};

export const setDocumentTitle = (environment = 'exante') => {
  document.title = `Run - ${capitalize(environment)}`;
};

export const initBranding = (brandName: string, favicon: string) => {
  if (brandName !== 'twino' && favicon) {
    setFavicon(favicon);
  }
  setDocumentTitle(brandName);
};

export async function getBrandingConfig(): Promise<BrandingEnvConfig> {
  // const isStage = window.location.hostname.includes('stage');
  // const isLocalhost = window.location.hostname.includes('localhost');
  // const isDemo = window.location.hostname.includes('demo');
  const defaultResult = (defaultBrandingEnv as any).default;
  // We use branding-env-exante.config for localhost
  if (isLocalhost) {
    return defaultResult;
  }
  let result = null;

  const remoteUrl = `/branding-env.config.json`;
  try {
    const response = await fetch(remoteUrl);
    result = await response.json();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`Can't get branding config at ${remoteUrl}`);
  }

  if (result) {
    return result;
  }
  return defaultResult;
}
