import { styled } from '@mui/material';
import { IconButton } from 'react-ui-kit-exante';

export const StyledHeader = styled('div')`
  height: 56px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0 40px;
  column-gap: 16px;
`;

export const StyledUserActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const StyledCurrencySelectorWrapper = styled('div')`
  display: flex;
  width: 100px;
  align-items: center;
`;

export const StyledLogoutButton = styled(IconButton)`
  margin-left: 32px;
`;
