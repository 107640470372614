import { SyntheticEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from 'react-ui-kit-exante';

import {
  CPRM_UI_SERVICE_URL_PREFIX,
  CRM_UI_SERVICE_URL_PREFIX,
} from '../../config/const';
import { UserProfileName } from '../../entities/user/ProfileName/UserProfileName';
import { ProfileSettings } from '../../entities/user/ProfileSettings/ProfileSettings';
import { UserInfoSelector } from '../../entities/user/selectors';
import { useAppSelector } from '../../store/hooks';
import { useAuthContext } from '../auth/AuthProvider';
import { AuthSelector } from '../auth/selectors';
import { GlobalSearch } from '../globalSearch/GlobalSearch';
import { useFavourites } from '../menu/useFavourites';
import { useAuthDBService } from '../services/AuthDB.service';
import { getStringHash } from '../shared/helpers';
import { useBreadcrumbs } from '../shared/useBreadcrumb/useBreadcrumb';

import { CurrencySelector } from './CurrencySelector/CurrencySelector';
import {
  StyledCurrencySelectorWrapper,
  StyledHeader,
  StyledLogoutButton,
  StyledUserActions,
} from './Header.styled';

export const Header = () => {
  const { firstName, lastName, id: userId } = useAppSelector(UserInfoSelector);
  const authState = useAppSelector(AuthSelector);
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const { logout } = useAuthContext();
  const {
    breadcrumbs,
    bookmarks,
    selectedBookmark,
    lastBreadcrumb,
    handleChangeBookmark,
  } = useBreadcrumbs();

  const authDBService = useAuthDBService();

  const profileName = `${firstName} ${lastName}`;

  const history = useHistory();

  const { toggleFavourite, isActiveFavourite } = useFavourites(lastBreadcrumb);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFavourite = () => {
    const { text, url } = lastBreadcrumb;

    toggleFavourite({
      id: getStringHash(text + url),
      linkTo: url,
      text,
    });
  };

  const onChangeSelected = (url: string) => {
    handleChangeBookmark(url);
  };

  const handleLogout = useCallback(() => {
    if (userId && authState.currentSessionId) {
      authDBService.removeSessionByUserId(userId, authState.currentSessionId);
    }

    logout();
  }, [authState.currentSessionId, logout, userId]);

  const isNoHistoryBookmark = [
    'bo',
    CRM_UI_SERVICE_URL_PREFIX,
    CPRM_UI_SERVICE_URL_PREFIX,
  ].includes(selectedBookmark?.split('/')[1] || '');

  return (
    <StyledHeader className="Header">
      {breadcrumbs.length > 0 && (
        <Breadcrumbs
          bookmarks={bookmarks}
          hasFavourites
          history={isNoHistoryBookmark ? false : history}
          isFavourite={isActiveFavourite}
          onChangeSelected={onChangeSelected}
          onClickFavourite={handleFavourite}
          selectedBookmark={selectedBookmark}
          value={breadcrumbs}
        />
      )}
      <GlobalSearch />
      <StyledUserActions>
        {profileName && (
          <UserProfileName profileName={profileName} onClick={handleClick} />
        )}
        {anchorEl && (
          <ProfileSettings anchorEl={anchorEl} handleClose={handleClose} />
        )}
        <StyledCurrencySelectorWrapper>
          <CurrencySelector />
        </StyledCurrencySelectorWrapper>
        <StyledLogoutButton
          iconName="ExitIcon"
          iconColor="secondary"
          data-test-id="header-module__button--logout"
          onClick={handleLogout}
        />
      </StyledUserActions>
    </StyledHeader>
  );
};
