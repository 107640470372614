import { ComponentType } from 'react';

export enum Routes {
  AUTH = '/',
  MODULE_AUTH = '/*/auth',
  RECON = '/recon/*',
  SYMBOLDB = '/symboldb',
  SYMBOLDB_THEMES = '/symboldb/themes',
  PROFILE = '/profile',
  CORPORATE_ACTIONS = '/corporate-actions/*',
  STOCK_LENDING = '/stock-lending/*',
  USERS = '/users',
  USER = '/users/:userName',
  WEBBO = '/bo/*',
  NOTIFICATIONS_SHAPER = '/ns/*',
  SYMBOLDB_UI = '/symboldb-ui/*',
  RECON_EMIR = '/recon/emir/*',
  CRM = '/crm/*',
  CRM_UI_REACT = '/crm-ui/*',
  CPRM_UI_REACT = '/cprm-ui/*',
  CPRM = '/cprm/*',
  WFE = '/wfe/*',
  REPORTING = '/reporting/*',
  DASHBOARD = '/dashboard',
  BRANDING = '/branding/*',
  ORDER_MANAGEMENT = '/om/*',
  REMUS = '/remus/*',
  STRESS_TEST = '/stress-test/*',
  СRM_ADMIN = '/crm-django-admin/',
  CPRM_ADMIN = '/cprm-django-admin/',
  PARTNER_ADMIN = '/partner-admin*',
}

export interface IRoute {
  path: typeof Routes[keyof typeof Routes];
  component: ComponentType<React.PropsWithChildren<unknown>>;
  layout?: ComponentType<React.PropsWithChildren<unknown>>;
  routes?: Array<IRoute>;
  isNotPrivate?: boolean;
  name?: string;
}

export type Params = {
  actionId: string;
  userName: string;
};
