import { RequestGlobalSearchResponse } from '../../services/Types/nodeback.types';
import { ParseSearchReturn } from '../types';

export function parseShaperSearch(
  { counts, data, metadata }: RequestGlobalSearchResponse['notificationShaper'],
  dict: Record<string, string>,
): ParseSearchReturn {
  const searchResult = data.map(({ link, text, tab }) => {
    return {
      link: `/ns${link}`,
      path: dict[`/ns/${tab}`],
      text,
    };
  });

  const newCounts = Object.fromEntries(
    Object.entries(metadata?.menuCounts || counts).map(([tab, count]) => [
      `/ns/${tab}`,
      count,
    ]),
  );

  return { searchResult, newCounts };
}
