import { createTheme } from 'react-ui-kit-exante';

import { useLocalStorage } from '../hooks/useLocalStorage';

import { LIGHT_THEME, LOCAL_STORAGE_KEY_COLOR_SCHEME } from './const';
import { TColorScheme } from './types';

const localDefaultTheme = {};

const localDarkTheme = {};

const ComputedTheme = () => {
  const { getItem } = useLocalStorage();

  const mode =
    (getItem(LOCAL_STORAGE_KEY_COLOR_SCHEME) as TColorScheme) || LIGHT_THEME;

  return mode === LIGHT_THEME ? localDefaultTheme : localDarkTheme;
};

export const theme = createTheme(ComputedTheme() as any);
